// /layouts/PublicLayout.js
import React from 'react';
import Header from '../components/LandingHeader';
import Footer from '../components/LandingFooter';

const PublicLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default PublicLayout;
