import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  House,
  GraduationCap,
  Coins,
  BookOpen,
  List,
  X,
  Gear,
  FileText
} from '@phosphor-icons/react';

const navigation = [
  { 
    name: 'Accueil', 
    href: '/dashboard', 
    icon: (props) => <House {...props} weight="regular" />,
    activeIcon: (props) => <House {...props} weight="fill" />
  },
  { 
    name: 'Mon parcours', 
    href: '/experiences', 
    icon: (props) => <GraduationCap {...props} weight="regular" />,
    activeIcon: (props) => <GraduationCap {...props} weight="fill" />
  },
  { 
    name: 'Modèles', 
    href: '/templates', 
    icon: (props) => <FileText {...props} weight="regular" />,
    activeIcon: (props) => <FileText {...props} weight="fill" />
  },
  { 
    name: 'Crédits', 
    href: '/credits', 
    icon: (props) => <Coins {...props} weight="regular" />,
    activeIcon: (props) => <Coins {...props} weight="fill" />
  },
  { 
    name: 'Blog', 
    href: '/blog', 
    icon: (props) => <BookOpen {...props} weight="regular" />,
    activeIcon: (props) => <BookOpen {...props} weight="fill" />
  },
];

const defaultAvatar = 'https://via.placeholder.com/40';

const NavBar = ({ user, onLogout, onProfileImageLoad }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="bg-blue-500 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-white text-xl font-semibold">clicwork.</span>
            </div>
            {/* Navigation desktop */}
            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              {navigation.map((item) => {
                const active = isActive(item.href);
                const IconComponent = active ? item.activeIcon : item.icon;
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150 ease-in-out
                      ${active 
                        ? 'text-white bg-blue-600' 
                        : 'text-blue-50 hover:text-white hover:bg-blue-600'
                      }`}
                  >
                    <IconComponent className="h-5 w-5 mr-1.5" />
                    {item.name}
                  </Link>
                );
              })}
            </div>
          </div>

          {/* Profile section */}
          <div className="flex items-center">
            {/* Profile desktop */}
            <div className="hidden md:flex items-center">
              <div className="relative" ref={profileMenuRef}>
                <button
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                  className="flex rounded-full bg-blue-600/10 hover:bg-blue-600/20 transition-colors duration-200 p-0.5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <img
                    src={user?.profilePicture || defaultAvatar}
                    alt="Profile"
                    className="h-8 w-8 rounded-full object-cover"
                    onLoad={onProfileImageLoad}
                    loading="eager"
                  />
                </button>

                {/* Flyout menu */}
                {showProfileMenu && (
                  <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Link
                      to="/settings"
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setShowProfileMenu(false)}
                    >
                      <Gear className="mr-2 h-4 w-4" weight="regular" />
                      Paramètres
                    </Link>
                    <div className="my-1 border-t border-gray-200" />
                    <button
                      onClick={() => {
                        onLogout();
                        setShowProfileMenu(false);
                      }}
                      className="flex w-full items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                    >
                      <X className="mr-2 h-4 w-4" weight="regular" />
                      Déconnexion
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-600 focus:outline-none"
              >
                <span className="sr-only">Ouvrir le menu principal</span>
                {isOpen ? (
                  <X size={24} weight="regular" />
                ) : (
                  <List size={24} weight="regular" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Menu mobile */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-blue-500 shadow-lg">
          {navigation.map((item) => {
            const active = isActive(item.href);
            const IconComponent = active ? item.activeIcon : item.icon;
            return (
              <Link
                key={item.name}
                to={item.href}
                className={`flex items-center px-3 py-2 rounded-md text-base font-medium transition-colors duration-150 ease-in-out
                  ${active 
                    ? 'text-white bg-blue-600' 
                    : 'text-blue-50 hover:text-white hover:bg-blue-600'
                  }`}
                onClick={() => setIsOpen(false)}
              >
                <IconComponent className="h-5 w-5 mr-1.5" />
                {item.name}
              </Link>
            );
          })}
          {/* Mobile profile menu */}
          {user && (
            <div className="border-t border-blue-400 pt-4 mt-4">
              <Link
                to="/settings"
                className="flex items-center px-3 py-2 text-base font-medium text-white hover:bg-blue-600 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                <Gear className="mr-2 h-5 w-5" weight="regular" />
                Paramètres
              </Link>
              <button
                onClick={() => {
                  setIsOpen(false);
                  onLogout();
                }}
                className="flex w-full items-center px-3 py-2 mt-1 text-base font-medium text-red-300 hover:text-red-100 hover:bg-blue-600 rounded-md"
              >
                <X className="mr-2 h-5 w-5" weight="regular" />
                Déconnexion
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
