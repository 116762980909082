import React from 'react';
import { motion } from 'framer-motion';

const illustrations = {
  welcome: (
    <svg className="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.circle 
        cx="100" 
        cy="100" 
        r="50" 
        fill="#2563EB"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      />
      <motion.path
        d="M85 100L95 110L115 90"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      />
    </svg>
  ),
  personal: (
    <svg className="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.circle
        cx="100"
        cy="80"
        r="30"
        fill="#2563EB"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.path
        d="M50 160C50 126.863 76.863 100 110 100H90C123.137 100 150 126.863 150 160"
        stroke="#2563EB"
        strokeWidth="4"
        strokeLinecap="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      />
    </svg>
  ),
  address: (
    <svg className="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M100 30L160 160H40L100 30Z"
        fill="#2563EB"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.circle
        cx="100"
        cy="80"
        r="10"
        fill="white"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      />
    </svg>
  ),
  professional: (
    <svg className="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.rect
        x="40"
        y="60"
        width="120"
        height="80"
        rx="8"
        fill="#2563EB"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.path
        d="M70 100H130M70 120H110"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      />
    </svg>
  )
};

const OnboardingIllustration = ({ type, className = "" }) => {
  return (
    <div className={`w-48 h-48 mx-auto opacity-75 ${className}`}>
      {illustrations[type] || illustrations.welcome}
    </div>
  );
};

export default OnboardingIllustration;
