import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDU8ZPGsO6p6z8KYI3LvKaSe8HJBjvMbU8",
  authDomain: "clicwork.fr",
  databaseURL: "https://eyejob-25bf3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eyejob-25bf3",
  storageBucket: "eyejob-25bf3.firebasestorage.app",
  messagingSenderId: "135153807949",
  appId: "1:135153807949:web:684475d6a080d55cd4a204",
  measurementId: "G-WL3GQXLZ02"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Configurer la persistance de l'authentification
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistance configurée silencieusement
  })
  .catch((error) => {
    // Gestion silencieuse de l'erreur de persistance
  });

export { app, auth, db, storage };
