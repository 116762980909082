import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-gray-50 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Flex container */}
        <div className="flex flex-wrap justify-between items-center gap-4">
          {/* Copyright */}
          <p className="text-xs text-gray-600">
            © 2024 eyejob. Tous droits réservés.
          </p>

          {/* Social Links */}
          <ul className="flex items-center space-x-4">
            <li>
              <a
                href="#"
                className="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2"
              >
                Dribbble
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2"
              >
                Github
              </a>
            </li>
          </ul>

          {/* Theme Switcher */}
          <div className="flex items-center">
            <button
              type="button"
              className="flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-700"
              data-hs-theme-click-value="dark"
            >
              <span className="sr-only">Mode sombre</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
              </svg>
            </button>
            <button
              type="button"
              className="flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 hidden dark:flex dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-700"
              data-hs-theme-click-value="light"
            >
              <span className="sr-only">Mode clair</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M12 2v2"></path>
                <path d="M12 20v2"></path>
                <path d="m4.93 4.93 1.41 1.41"></path>
                <path d="m17.66 17.66 1.41 1.41"></path>
                <path d="M2 12h2"></path>
                <path d="M20 12h2"></path>
                <path d="m6.34 17.66-1.41 1.41"></path>
                <path d="m19.07 4.93-1.41 1.41"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
