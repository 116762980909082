import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../services/firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import PublicLayout from '../../layouts/PublicLayout';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const checkProfileConfigured = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      if (userData && userData.ConfigureAccountCheck) {
        navigate('/dashboard');
      } else {
        navigate('/configure-account');
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du profil:', error);
      navigate('/configure-account'); // Par défaut, on redirige vers la configuration
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      checkProfileConfigured(user.uid);
    }
  }, []);

  const handleEmailLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        checkProfileConfigured(user.uid);
      })
      .catch((error) => {
        console.error('Erreur de connexion :', error);
        alert('Échec de la connexion. Veuillez vérifier vos informations.');
      });
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        checkProfileConfigured(user.uid);
      })
      .catch((error) => {
        console.error('Erreur de connexion Google :', error);
        alert('Échec de la connexion avec Google.');
      });
  };

  return (
    <PublicLayout>
      <div className="overflow-hidden relative min-h-screen flex items-center justify-center">
        {/* Background Gradients */}
        <div aria-hidden="true" className="absolute inset-0 -z-10">
          <div className="flex absolute top-0 start-0 -z-10">
            <div className="bg-blue-200 opacity-30 blur-3xl w-[1036px] h-[600px]"></div>
            <div className="bg-gray-200 opacity-90 blur-3xl w-[577px] h-[300px] translate-y-32"></div>
          </div>
        </div>

        {/* Login Box */}
        <div className="w-full max-w-md bg-white border border-gray-200 rounded-xl shadow-sm p-6 sm:p-8 relative z-10">
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold text-blue-600">Eyejob</h1>
            <h2 className="text-2xl font-bold text-gray-800 mt-4">Connexion</h2>
            <p className="mt-2 text-sm text-gray-600">
              Vous n'avez pas encore créé de compte ?{' '}
              <a className="text-blue-600 hover:underline font-medium ml-1" href="/signup">
                Inscrivez-vous
              </a>
            </p>
          </div>

          <button
            onClick={handleGoogleLogin}
            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none"
          >
            <svg className="w-4 h-auto" viewBox="0 0 46 47" fill="none">
              {/* SVG Paths */}
              <path d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z" fill="#4285F4" />
              <path d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z" fill="#34A853" />
              <path d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z" fill="#FBBC05" />
              <path d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z" fill="#EB4335" />
            </svg>
            Connexion avec Google
          </button>

          <div className="py-3 flex items-center text-xs text-gray-400 uppercase">
            <span className="flex-1 border-t border-gray-200"></span>
            <span className="px-3">OU</span>
            <span className="flex-1 border-t border-gray-200"></span>
          </div>

          <form onSubmit={handleEmailLogin}>
            <div className="grid gap-y-4">
              <div>
                <label htmlFor="email" className="block text-sm mb-2">Adresse email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm mb-2">Mot de passe</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:bg-blue-700"
              >
                Connexion
              </button>
            </div>
          </form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Login;
