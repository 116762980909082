import React, { useState, useEffect } from 'react';

const Loader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Laisser le temps à l'animation de se terminer avant de retirer le composant du DOM
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1500); // Durée de l'animation

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-white transition-all duration-300 ease-in-out z-50 flex items-center justify-center"
         style={{ 
           opacity: isVisible ? 1 : 0,
           backdropFilter: 'blur(8px)',
           WebkitBackdropFilter: 'blur(8px)'
         }}>
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

export default Loader;
