import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp, deleteField } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input';
import { Autocomplete } from '@react-google-maps/api';
import { useGoogleMaps } from '../../hooks/useGoogleMaps';
import 'react-phone-number-input/style.css';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import OnboardingIllustration from '../../components/OnboardingIllustration';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const globalStyles = `
  .phone-input-container .special-label {
    display: none;
  }
  .PhoneInput {
    display: flex;
    align-items: center;
    border: 1px solid #D1D5DB;
    border-radius: 0.75rem;
    padding: 0.1rem 0.75rem;
    background: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .PhoneInput:focus-within {
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding-right: 0.75rem;
    margin-right: 0.5rem;
    border-right: 1px solid #E5E7EB;
  }
  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }
  .PhoneInputCountryIcon {
    width: 2rem;
    height: auto;
    border: none !important;
    box-shadow: none !important;
  }
  .PhoneInputInput {
    flex: 1;
    min-width: 0;
    border: none;
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    background: transparent;
    direction: ltr;
    text-align: left;
    caret-color: transparent;
  }
  .PhoneInputInput:focus {
    outline: none;
    caret-color: auto;
  }
  /* Style pour positionner le curseur après le +33 */
  .PhoneInputInput[type="tel"] {
    padding-left: 3ch;
  }
  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    border: 1px solid #D1D5DB;
  }
  select:focus {
    outline: none;
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
`;

const phoneInputStyles = `
  .custom-phone-input {
    position: relative;
    font-size: 0.875rem;
  }
  
  .custom-phone-input .PhoneInput {
    display: flex;
    align-items: center;
    border: 1px solid #D1D5DB;
    border-radius: 0.75rem;
    background-color: white;
    transition: all 0.2s;
  }
  
  .custom-phone-input.error .PhoneInput {
    border-color: #EF4444;
  }
  
  .custom-phone-input .PhoneInput:focus-within {
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
  }
  
  .custom-phone-input .PhoneInputCountry {
    position: relative;
    align-items: center;
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #E5E7EB;
    margin-right: 0;
  }
  
  .custom-phone-input .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-phone-input .PhoneInputCountryIcon {
    width: 24px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  .custom-phone-input .PhoneInputCountrySelectArrow {
    margin-left: 0.5rem;
    width: 0.75rem;
    height: 0.75rem;
    border-style: solid;
    border-color: #6B7280;
    border-width: 0.15em 0.15em 0 0;
    transform: rotate(135deg);
    margin-top: -0.25rem;
  }
  
  .custom-phone-input .PhoneInputInput {
    flex: 1;
    border: none;
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background: transparent;
    outline: none !important;
    min-width: 0;
  }
  
  .custom-phone-input .PhoneInputInput:focus {
    outline: none !important;
  }
`;

// Fonction pour calculer l'âge à partir d'une date de naissance
const calculateAge = (birthDate) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
};

const sections = [
  {
    id: 'welcome',
    title: 'Bienvenue',
    description: 'Commençons la configuration de votre compte',
    fields: []
  },
  {
    id: 'general',
    title: 'Informations générales',
    description: 'Vos informations de base',
    fields: ['firstName', 'lastName']
  },
  {
    id: 'about',
    title: 'À propos de vous',
    description: 'Quelques informations personnelles',
    fields: ['birthDate', 'gender']
  },
  {
    id: 'contact',
    title: 'Coordonnées',
    description: 'Vos informations de contact',
    fields: ['address', 'addressComplement', 'postalCode', 'city', 'country', 'phone']
  },
  {
    id: 'professional',
    title: 'Profil professionnel',
    description: 'Vos compétences et préférences',
    fields: ['languages', 'jobSearchDuration', 'drivingLicense']
  },
  {
    id: 'references',
    title: 'Références',
    description: 'Vos liens professionnels',
    fields: ['linkedinUrl', 'discoverySource']
  },
  {
    id: 'photo',
    title: 'Photo de profil',
    description: 'Ajoutez une photo à votre profil',
    fields: ['profilePhoto']
  },
  {
    id: 'summary',
    title: 'Récapitulatif',
    description: 'Vérifiez vos informations',
    fields: []
  }
];

const fields = {
  firstName: {
    type: 'text',
    label: 'Prénom',
    placeholder: 'Votre prénom',
    validation: (value) => {
      if (!value || value.length < 2) return 'Le prénom doit contenir au moins 2 caractères';
      return null;
    }
  },
  lastName: {
    type: 'text',
    label: 'Nom',
    placeholder: 'Votre nom',
    validation: (value) => {
      if (!value || value.length < 2) return 'Le nom doit contenir au moins 2 caractères';
      return null;
    }
  },
  birthDate: {
    type: 'date',
    label: 'Date de naissance',
    validation: (value) => {
      if (!value) return 'La date de naissance est requise';
      const age = calculateAge(new Date(value));
      if (age < 18) return 'Vous devez avoir au moins 18 ans';
      return null;
    }
  },
  gender: {
    type: 'select',
    label: 'Genre',
    options: [
      { value: 'male', label: 'Homme' },
      { value: 'female', label: 'Femme' },
      { value: 'other', label: 'Autre' }
    ],
    validation: (value) => !value ? 'Le genre est requis' : null
  },
  phone: {
    type: 'phone',
    label: 'Numéro de téléphone',
    validation: (value) => !value ? 'Le numéro de téléphone est requis' : null
  },
  address: {
    type: 'text',
    label: 'Adresse',
    placeholder: 'Votre adresse',
    validation: (value) => !value ? 'L\'adresse est requise' : null
  },
  addressComplement: {
    type: 'text',
    label: 'Complément d\'adresse',
    placeholder: 'Appartement, étage, etc.',
    optional: true
  },
  postalCode: {
    type: 'text',
    label: 'Code postal',
    placeholder: '75000',
    validation: (value) => {
      if (!value) return 'Le code postal est requis';
      if (!/^\d{5}$/.test(value)) return 'Le code postal doit contenir 5 chiffres';
      return null;
    }
  },
  city: {
    type: 'text',
    label: 'Ville',
    placeholder: 'Votre ville',
    validation: (value) => !value ? 'La ville est requise' : null
  },
  country: {
    type: 'text',
    label: 'Pays',
    placeholder: 'Votre pays',
    validation: (value) => !value ? 'Le pays est requis' : null
  },
  languages: {
    type: 'languages',
    label: 'Langues parlées',
    options: [
      { value: 'fr', label: 'Français', flag: '🇫🇷' },
      { value: 'en', label: 'Anglais', flag: '🇬🇧' },
      { value: 'es', label: 'Espagnol', flag: '🇪🇸' },
      { value: 'de', label: 'Allemand', flag: '🇩🇪' },
      { value: 'it', label: 'Italien', flag: '🇮🇹' },
      { value: 'pt', label: 'Portugais', flag: '🇵🇹' },
      { value: 'nl', label: 'Néerlandais', flag: '🇳🇱' },
      { value: 'ru', label: 'Russe', flag: '🇷🇺' },
      { value: 'ar', label: 'Arabe', flag: '🇸🇦' },
      { value: 'zh', label: 'Chinois', flag: '🇨🇳' },
      { value: 'ja', label: 'Japonais', flag: '🇯🇵' },
      { value: 'ko', label: 'Coréen', flag: '🇰🇷' }
    ],
    validation: (value) => {
      if (!value || value.length === 0) return 'Sélectionnez au moins une langue';
      return null;
    }
  },
  jobSearchDuration: {
    type: 'select',
    label: 'Depuis combien de temps cherchez-vous un emploi ?',
    options: [
      { value: 'not_looking', label: 'Je ne cherche pas encore' },
      { value: 'less_than_month', label: 'Moins d\'un mois' },
      { value: '1_3_months', label: '1 à 3 mois' },
      { value: '3_6_months', label: '3 à 6 mois' },
      { value: 'more_than_6', label: 'Plus de 6 mois' }
    ],
    validation: (value) => !value ? 'Cette information est requise' : null
  },
  drivingLicense: {
    type: 'select',
    label: 'Avez-vous le permis de conduire ?',
    options: [
      { value: 'yes', label: 'Oui' },
      { value: 'no', label: 'Non' },
      { value: 'in_progress', label: 'En cours' }
    ],
    validation: (value) => !value ? 'Cette information est requise' : null
  },
  linkedinUrl: {
    type: 'url',
    label: 'Profil LinkedIn',
    placeholder: 'https://linkedin.com/in/votre-profil',
    optional: true,
    validation: (value) => {
      if (!value) return null; // Optional field
      if (!value.startsWith('https://linkedin.com/') && !value.startsWith('https://www.linkedin.com/')) {
        return 'L\'URL doit commencer par https://linkedin.com/';
      }
      return null;
    }
  },
  discoverySource: {
    type: 'select',
    label: 'Comment avez-vous découvert Clicwork ?',
    options: [
      { value: 'search', label: 'Moteur de recherche' },
      { value: 'social', label: 'Réseaux sociaux' },
      { value: 'friend', label: 'Recommandation' },
      { value: 'other', label: 'Autre' }
    ],
    validation: (value) => !value ? 'Cette information est requise' : null
  },
  profilePhoto: {
    type: 'file',
    label: 'Photo de profil',
    description: 'PNG, JPG jusqu\'à 10MB',
    optional: true,
    validation: (value) => {
      if (!value) return null; // Optional field
      if (value.size > 10 * 1024 * 1024) return 'La photo ne doit pas dépasser 10MB';
      if (!['image/jpeg', 'image/png'].includes(value.type)) return 'Format accepté : JPG ou PNG';
      return null;
    }
  }
};

const ConfigureAccount = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Charger les données sauvegardées au démarrage
  useEffect(() => {
    const loadSavedProgress = async () => {
      try {
        // Vérifier d'abord le localStorage
        const savedLocal = localStorage.getItem('onboardingProgress');
        if (savedLocal) {
          const { answers: savedAnswers, sectionIndex } = JSON.parse(savedLocal);
          setAnswers(savedAnswers);
          setCurrentSectionIndex(sectionIndex);
        }

        // Puis vérifier Firestore si l'utilisateur est connecté
        if (auth.currentUser) {
          const db = getFirestore();
          const userDoc = doc(db, 'users', auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          
          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData.onboardingProgress) {
              // Si les données Firestore sont plus récentes, les utiliser
              const firestoreTimestamp = userData.onboardingProgress.timestamp?.toMillis() || 0;
              const localTimestamp = savedLocal ? JSON.parse(savedLocal).timestamp : 0;
              
              if (firestoreTimestamp > localTimestamp) {
                setAnswers(userData.onboardingProgress.answers || {});
                setCurrentSectionIndex(userData.onboardingProgress.sectionIndex || 0);
              }
            }
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement de la progression:', error);
      }
    };

    loadSavedProgress();
  }, [auth.currentUser]);

  // Sauvegarder la progression automatiquement
  useEffect(() => {
    const saveProgress = async () => {
      // Créer une copie des réponses sans les objets File
      const cleanAnswers = Object.entries(answers).reduce((acc, [key, value]) => {
        // Si c'est un objet File, on ne sauvegarde que le nom
        if (value instanceof File) {
          acc[key] = {
            type: 'file',
            name: value.name,
            size: value.size,
            lastModified: value.lastModified
          };
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      const progressData = {
        answers: cleanAnswers,
        sectionIndex: currentSectionIndex,
        timestamp: Date.now()
      };

      // Sauvegarder dans localStorage
      localStorage.setItem('onboardingProgress', JSON.stringify(progressData));

      // Sauvegarder dans Firestore si l'utilisateur est connecté
      if (auth.currentUser) {
        try {
          const db = getFirestore();
          const userDoc = doc(db, 'users', auth.currentUser.uid);
          await setDoc(userDoc, {
            onboardingProgress: {
              ...progressData,
              timestamp: serverTimestamp()
            }
          }, { merge: true });
        } catch (error) {
          console.error('Erreur lors de la sauvegarde dans Firestore:', error);
        }
      }
    };

    // Debounce la sauvegarde pour éviter trop d'appels
    const timeoutId = setTimeout(saveProgress, 1000);
    return () => clearTimeout(timeoutId);
  }, [answers, currentSectionIndex, auth.currentUser]);

  // Nettoyer la progression une fois terminée
  const clearProgress = useCallback(async () => {
    localStorage.removeItem('onboardingProgress');
    
    if (auth.currentUser) {
      try {
        const db = getFirestore();
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        await setDoc(userDoc, {
          onboardingProgress: deleteField()
        }, { merge: true });
      } catch (error) {
        console.error('Erreur lors du nettoyage de la progression:', error);
      }
    }
  }, [auth.currentUser]);

  const { isLoaded, loadError, onLoad, onPlaceChanged } = useGoogleMaps();

  const currentSection = sections[currentSectionIndex];
  const progress = ((currentSectionIndex + 1) / sections.length) * 100;

  const validateSection = () => {
    const newErrors = {};
    let isValid = true;

    currentSection.fields.forEach(fieldId => {
      const field = fields[fieldId];
      if (!field.optional) {
        const value = answers[fieldId];
        const error = field.validation ? field.validation(value) : (!value ? "Ce champ est requis" : null);
        if (error) {
          newErrors[fieldId] = error;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    const currentSection = sections[currentSectionIndex];
    const errors = {};
    
    // Valider tous les champs de la section actuelle
    for (const fieldId of currentSection.fields) {
      const field = fields[fieldId];
      const value = answers[fieldId];
      
      if (!field.optional) {
        const error = field.validation ? field.validation(value) : (!value ? 'Ce champ est requis' : null);
        if (error) {
          errors[fieldId] = error;
        }
      }
    }
    
    // S'il y a des erreurs, les afficher
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    
    // Si tout est valide, passer à la section suivante
    if (currentSectionIndex < sections.length - 1) {
      setCurrentSectionIndex(prev => prev + 1);
      setErrors({});
    }
  };

  const handlePrevious = () => {
    setCurrentSectionIndex(prev => Math.max(prev - 1, 0));
  };

  const handleChange = (fieldId, value) => {
    setErrors(prev => ({ ...prev, [fieldId]: null }));
    
    // Pour les champs de type téléphone, s'assurer que la valeur est une chaîne
    if (fields[fieldId].type === 'phone' && value !== null && value !== undefined) {
      value = value.toString();
    }
    
    setAnswers(prev => ({ ...prev, [fieldId]: value }));
    
    // Valider le champ si nécessaire
    const error = fields[fieldId].validation?.(value);
    if (error) {
      setErrors(prev => ({ ...prev, [fieldId]: error }));
    }
  };

  const renderField = (fieldId) => {
    const field = fields[fieldId];
    const value = answers[fieldId] || '';
    const error = errors[fieldId];

    switch (field.type) {
      case 'text':
      case 'url':
        if (fieldId === 'address') {
          if (!isLoaded) return <div>Chargement...</div>;
          if (loadError) return <div>Erreur de chargement de Google Maps</div>;

          return (
            <div key={fieldId} className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={() => onPlaceChanged((address) => {
                  setAnswers(prev => ({
                    ...prev,
                    ...address
                  }));
                })}
                options={{
                  types: ['address'],
                  componentRestrictions: { country: 'FR' }
                }}
              >
                <input
                  type="text"
                  value={value || ''}
                  onChange={(e) => handleChange(fieldId, e.target.value)}
                  placeholder={field.placeholder}
                  className={`w-full py-3 px-4 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 rounded-xl shadow-sm ${
                    error ? 'border-red-300' : 'border-gray-300'
                  }`}
                />
              </Autocomplete>
              <div className="mt-2 flex items-center text-sm text-gray-600">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                <p className="text-blue-500">Votre adresse nous est utile pour la création du CV</p>
              </div>
              {error && <p className="text-sm text-red-500">{error}</p>}
            </div>
          );
        }
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label} {field.optional && <span className="text-gray-400">(facultatif)</span>}
            </label>
            <input
              type={field.type}
              value={value || ''}
              onChange={(e) => handleChange(fieldId, e.target.value)}
              placeholder={field.placeholder}
              className={`block w-full py-3 px-4 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 rounded-xl shadow-sm ${
                error ? 'border-red-300' : ''
              }`}
            />
            {fieldId === 'linkedinUrl' && (
              <div className="mt-2 flex items-center text-sm text-gray-600">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                <p className="text-blue-500">Montrez que vous êtes engagé(e) dans votre carrière !</p>
              </div>
            )}
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        );

      case 'date':
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type="date"
              value={value || ''}
              onChange={(e) => handleChange(fieldId, e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className={`block w-full py-3 px-4 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 rounded-xl shadow-sm ${
                error ? 'border-red-300' : ''
              }`}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        );

      case 'phone':
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className={`custom-phone-input ${error ? 'error' : ''}`}>
              <PhoneInput
                defaultCountry="FR"
                value={value}
                onChange={(phoneNumber) => handleChange(fieldId, phoneNumber)}
                international
              />
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-600">
              <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
              <p className="text-blue-500">Le numéro de téléphone facilite la prise de contact auprès des recruteurs.</p>
            </div>
            {error && (
              <p className="mt-1 text-sm text-red-500">{error}</p>
            )}
          </div>
        );

      case 'select':
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <select
              value={value || ''}
              onChange={(e) => handleChange(fieldId, e.target.value)}
              className={`block w-full py-3 px-4 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 rounded-xl shadow-sm ${
                error ? 'border-red-300' : ''
              }`}
            >
              <option value="">Sélectionnez une option</option>
              {field.options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        );

      case 'languages':
        return (
          <div key={fieldId} className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
              {field.options.map(option => {
                const isSelected = value.includes(option.value);
                return (
                  <button
                    key={option.value}
                    type="button"
                    onClick={() => {
                      const newValue = isSelected
                        ? value.filter(v => v !== option.value)
                        : [...value, option.value];
                      handleChange(fieldId, newValue);
                    }}
                    className={`flex items-center justify-center space-x-2 p-3 rounded-xl border transition-all
                      ${isSelected 
                        ? 'bg-blue-50 border-blue-500 text-blue-700 font-medium shadow-sm' 
                        : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50'
                      }`}
                  >
                    <span className="text-xl">{option.flag}</span>
                    <span>{option.label}</span>
                  </button>
                );
              })}
            </div>
            
            {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
          </div>
        );

      case 'multiselect':
        const selectedValues = value || [];
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className="space-y-2">
              {field.options.map(option => (
                <label key={option.value} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option.value)}
                    onChange={(e) => {
                      const newValues = e.target.checked
                        ? [...selectedValues, option.value]
                        : selectedValues.filter(v => v !== option.value);
                      handleChange(fieldId, newValues);
                    }}
                    className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <span className="text-gray-700">{option.label}</span>
                </label>
              ))}
            </div>
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        );

      case 'file':
        const previewUrl = value && value instanceof File ? URL.createObjectURL(value) : null;
        
        return (
          <div key={fieldId} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative">
              {previewUrl ? (
                <div className="relative">
                  <img
                    src={previewUrl}
                    alt="Aperçu"
                    className="mx-auto h-32 w-32 rounded-full object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      URL.revokeObjectURL(previewUrl);
                      handleChange(fieldId, null);
                    }}
                    className="absolute -top-2 -right-2 rounded-full bg-red-100 p-1 text-red-600 hover:bg-red-200 focus:outline-none"
                  >
                    <svg className="w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              ) : (
                <>
                  <div className="space-y-1 text-center">
                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 015.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <span>Télécharger une photo</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                              handleChange(fieldId, file);
                            }
                          }}
                        />
                      </label>
                      <p className="pl-1">ou glisser-déposer</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG jusqu'à 10MB</p>
                  </div>
                </>
              )}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-600 py-2">
              <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
              <p className="text-blue-500">Les candidats avec une photo professionnelle sur leur CV ont <strong>36 fois plus de chances</strong> d'avoir un entretien !</p>
            </div>
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        );

      default:
        return null;
    }
  };

  const renderSummary = () => {
    return (
      <div className="space-y-6">
        <h2 className="text-xl font-semibold text-gray-900">Récapitulatif</h2>
        <div className="bg-white shadow rounded-xl p-6 space-y-4">
          {sections.flatMap(section => 
            section.fields.map(fieldId => {
              const field = fields[fieldId];
              const value = answers[fieldId];
              
              if (!value) return null;

              let displayValue = value;

              // Formatage spécifique selon le type
              switch (field.type) {
                case 'file':
                  displayValue = value.name;
                  break;
                case 'languages':
                  displayValue = value
                    .map(v => field.options.find(opt => opt.value === v)?.label)
                    .filter(Boolean)
                    .join(', ');
                  break;
                case 'select':
                  displayValue = field.options.find(opt => opt.value === value)?.label || value;
                  break;
                case 'date':
                  displayValue = new Date(value).toLocaleDateString('fr-FR');
                  break;
                default:
                  displayValue = value.toString();
              }

              return (
                <div key={fieldId} className="grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
                  <dd className="text-sm text-gray-900 col-span-2">{displayValue}</dd>
                </div>
              );
            })
          ).filter(Boolean)}
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    if (currentSectionIndex === sections.length - 1) {
      try {
        setIsLoading(true);
        const storage = getStorage();
        let profilePicture = '';

        // Créer les règles de sécurité pour permettre l'upload
        if (answers.profilePhoto) {
          try {
            const fileRef = ref(storage, `profilePictures/${auth.currentUser.uid}/${answers.profilePhoto.name}`);
            const result = await uploadBytes(fileRef, answers.profilePhoto);
            profilePicture = await getDownloadURL(result.ref);
          } catch (error) {
            console.error('Erreur lors de l\'upload de la photo:', error);
            // Continuer même si l'upload de la photo échoue
          }
        }

        // Mise à jour du profil utilisateur
        if (profilePicture) {
          await updateProfile(auth.currentUser, {
            photoURL: profilePicture // On garde photoURL ici car c'est le nom du champ dans Firebase Auth
          });
        }

        // Sauvegarde dans Firestore
        const db = getFirestore();
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        
        // Nettoyer les données avant la sauvegarde
        const cleanAnswers = Object.entries(answers).reduce((acc, [key, value]) => {
          if (value instanceof File) {
            // Pour les fichiers, sauvegarder l'URL et les métadonnées
            acc[key] = {
              type: 'file',
              name: value.name,
              size: value.size,
              url: key === 'profilePhoto' ? profilePicture : null
            };
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});

        await setDoc(userDoc, {
          ...cleanAnswers,
          profilePicture: profilePicture || null,
          discoverySource: answers.discoverySource,
          createdAt: new Date(),
          updatedAt: new Date(),
          ConfigureAccountCheck: true
        });

        // Nettoyer la progression une fois terminée
        await clearProgress();
        
        setIsLoading(false);
        navigate('/dashboard');
      } catch (error) {
        console.error('Erreur lors de la sauvegarde :', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Une erreur est survenue lors de la sauvegarde. Veuillez réessayer.'
        }));
        setIsLoading(false);
      }
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    // Nettoyer les URLs des aperçus lors du démontage du composant
    return () => {
      Object.values(answers).forEach(value => {
        if (value instanceof File && value._previewUrl) {
          URL.revokeObjectURL(value._previewUrl);
        }
      });
    };
  }, [answers]);

  return (
    <>
      <style>{phoneInputStyles}</style>
      <style>{globalStyles}</style>
      <div className="min-h-screen bg-gray-50 relative overflow-hidden">
        {/* Background gradient */}
        <div aria-hidden="true" className="flex absolute -top-96 start-1/2 transform -translate-x-1/2">
          <div className="bg-gradient-to-r from-violet-400/90 to-purple-200 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]"></div>
          <div className="bg-gradient-to-tl from-blue-200 via-blue-300 to-blue-200 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]"></div>
        </div>

        {/* Main content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 relative">
          <div className="max-w-2xl mx-auto">
            {/* Barre de progression */}
            <div className="mb-8">
              <div className="h-2 bg-white rounded-full overflow-hidden">
                <motion.div
                  className="h-full bg-gradient-to-r from-blue-500 to-blue-600"
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </div>

            {/* Logo clickwork */}
            <div className="text-center mb-8">
              <h1 className="text-white text-4xl font-bold tracking-tight">clicwork.</h1>
            </div>

            {/* Container principal */}
            <div className="bg-white rounded-xl shadow-2xl p-6 sm:p-8">
              <motion.div
                key={currentSectionIndex}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.4 }}
                className="space-y-6"
              >
                {/* Titre de la section */}
                <div className="text-center space-y-2">
                  <h2 className="text-3xl font-semibold text-gray-900">
                    {currentSection.title}
                  </h2>
                  <p className="text-gray-500">
                    {currentSection.description}
                  </p>
                </div>

                {/* Champs de la section */}
                <div className="space-y-6">
                  {currentSection.fields.map((fieldId, index) => {
                    // Si nous sommes dans la section contact, on regroupe les champs
                    if (currentSection.id === 'contact') {
                      if (fieldId === 'address') {
                        return (
                          <div key={fieldId}>
                            {renderField(fieldId)}
                            <div className="grid grid-cols-2 gap-4 mt-4">
                              <div>{renderField('addressComplement')}</div>
                              <div>{renderField('postalCode')}</div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                              <div>{renderField('city')}</div>
                              <div>{renderField('country')}</div>
                            </div>
                          </div>
                        );
                      }
                      // On ne rend pas ces champs individuellement car ils sont déjà rendus dans le groupe
                      if (['addressComplement', 'postalCode', 'city', 'country'].includes(fieldId)) {
                        return null;
                      }
                    }
                    return renderField(fieldId);
                  })}
                </div>

                {/* Récapitulatif */}
                {currentSection.id === 'summary' && renderSummary()}

                {/* Boutons de navigation */}
                <div className="flex justify-between items-center pt-6">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handlePrevious}
                    disabled={currentSectionIndex === 0}
                    className={`flex items-center px-4 py-2 rounded-xl text-gray-600 hover:text-gray-900 transition-colors ${
                      currentSectionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    <ArrowLeftIcon className="w-5 h-5 mr-2" />
                    Retour
                  </motion.button>

                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleSubmit}
                    className="relative overflow-hidden flex items-center px-6 py-2.5 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
                  >
                    <span className="relative z-10">
                      {currentSectionIndex === sections.length - 1 ? 'Terminer' : 'Continuer'}
                    </span>
                    <ArrowRightIcon className="w-5 h-5 ml-2 relative z-10" />
                  </motion.button>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigureAccount;
