import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import PublicLayout from '../../layouts/PublicLayout';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const checkProfileConfigured = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      if (userData && userData.ConfigureAccountCheck) {
        navigate('/dashboard');
      } else {
        navigate('/configure-account');
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du profil:', error);
      navigate('/configure-account'); // Par défaut, on redirige vers la configuration
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await checkProfileConfigured(userCredential.user.uid);
    } catch (error) {
      alert("Erreur lors de l'inscription :", error.message);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await checkProfileConfigured(result.user.uid);
    } catch (error) {
      alert("Erreur de connexion avec Google :", error.message);
    }
  };

  return (
    <PublicLayout>
      <div className="overflow-hidden relative min-h-screen flex items-center justify-center">
        {/* Signup Box */}
        <div className="w-full max-w-md bg-white border border-gray-200 rounded-xl shadow-sm p-6 sm:p-8 relative z-10">
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold text-blue-600">Eyejob</h1>
            <h2 className="text-2xl font-bold text-gray-800 mt-4">Inscription</h2>
            <p className="mt-2 text-sm text-gray-600">
              Vous avez déjà un compte ?
              <a className="text-blue-600 hover:underline font-medium ml-1" href="/login">
                Se connecter ici
              </a>
            </p>
          </div>

          <button
            onClick={signInWithGoogle}
            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none"
          >
            <svg className="w-4 h-auto" viewBox="0 0 46 47" fill="none">
              {/* SVG paths */}
              <path d="M46 24.0287C46 22.09..." fill="#4285F4" />
              <path d="M23.4694 47C29.8061 47..." fill="#34A853" />
              <path d="M10.1212 28.1413C9.6224..." fill="#FBBC05" />
              <path d="M23.4694 9.07688C27.8699..." fill="#EB4335" />
            </svg>
            S'inscrire avec Google
          </button>

          <div className="py-3 flex items-center text-xs text-gray-400 uppercase">
            <span className="flex-1 border-t border-gray-200"></span>
            <span className="px-3">OU</span>
            <span className="flex-1 border-t border-gray-200"></span>
          </div>

          <form onSubmit={handleSignup}>
            <div className="grid gap-y-4">
              <div>
                <label htmlFor="email" className="block text-sm mb-2">Adresse email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm mb-2">Mot de passe</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-3 text-gray-500"
                  >
                    {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                  </button>
                </div>
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm mb-2">Confirmez le mot de passe</label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-3 text-gray-500"
                  >
                    {showConfirmPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-blue-600 text-white hover:bg-blue-700"
              >
                S'inscrire
              </button>
            </div>
          </form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Signup;
