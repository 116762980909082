import { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const useGoogleMaps = () => {
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = (callback) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const addressComponents = place.address_components;
        const streetNumber = addressComponents.find(component => 
          component.types.includes('street_number')
        )?.long_name || '';
        const route = addressComponents.find(component => 
          component.types.includes('route')
        )?.long_name || '';
        const postalCode = addressComponents.find(component => 
          component.types.includes('postal_code')
        )?.long_name || '';
        const city = addressComponents.find(component => 
          component.types.includes('locality')
        )?.long_name || '';
        const country = addressComponents.find(component => 
          component.types.includes('country')
        )?.long_name || '';

        callback({
          address: `${streetNumber} ${route}`.trim(),
          postalCode,
          city,
          country
        });
      }
    }
  };

  return {
    isLoaded,
    loadError,
    onLoad,
    onPlaceChanged
  };
};
