import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    }, (error) => {
      console.error("Erreur d'authentification:", error);
      setIsAuthenticated(false);
    });

    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null) {
    return <div></div>; // Écran de chargement pendant la vérification
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;