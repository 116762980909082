import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import PublicLayout from '../../layouts/PublicLayout';

const Home = () => {
  const navigate = useNavigate();

  return (
    <PublicLayout>
      <div className="relative min-h-screen bg-white overflow-hidden">
        {/* Fond avec gradient moderne */}
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-br from-violet-50 via-blue-50 to-white opacity-70" />
          <div className="absolute -top-24 right-0 w-96 h-96 bg-blue-100 rounded-full mix-blend-multiply filter blur-3xl opacity-70" />
          <div className="absolute top-1/2 -left-24 w-96 h-96 bg-violet-100 rounded-full mix-blend-multiply filter blur-3xl opacity-70" />
        </div>

        {/* Contenu principal */}
        <div className="relative z-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
            {/* Hero Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-4xl mx-auto"
            >
              <span className="inline-flex items-center px-4 py-1.5 rounded-full bg-blue-50 border border-blue-100 mb-8">
                <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-2"></span>
                <span className="text-sm font-medium text-blue-600">
                  Propulsé par l'IA
                </span>
              </span>

              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 tracking-tight mb-6">
                Crée ton CV ou ta Lettre de Motivation 
                <span className="block mt-2 bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent">
                  en un clic
                </span>
              </h1>

              <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto leading-relaxed">
                Obtenez un CV ou une lettre professionnelle prêts à l'emploi en quelques secondes.
              </p>

              {/* Boutons CTA */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center mb-20">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate('/login')}
                  className="px-8 py-3 bg-white text-blue-600 rounded-xl font-semibold shadow-sm hover:shadow-md transition-all border border-gray-200 hover:border-blue-100"
                >
                  Se connecter autrement
                </motion.button>
              </div>

              {/* Statistiques */}
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-24">
                {[
                  { number: "10,000+", label: "CV générés" },
                  { number: "500+", label: "Entreprises partenaires" },
                  { number: "98%", label: "Satisfaction client" }
                ].map((stat, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.1 * index }}
                    className="p-6 rounded-xl bg-white border border-gray-100 shadow-sm hover:shadow-md transition-all"
                  >
                    <div className="font-bold text-2xl sm:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-violet-600 mb-1">
                      {stat.number}
                    </div>
                    <div className="text-gray-600">{stat.label}</div>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Features */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[
                {
                  title: "CV Optimisé",
                  description: "Notre IA analyse et optimise chaque section de votre CV pour maximiser vos chances"
                },
                {
                  title: "Templates Professionnels",
                  description: "Des modèles modernes et élégants, adaptés à votre secteur d'activité"
                },
                {
                  title: "Lettre de Motivation",
                  description: "Générez une lettre de motivation personnalisée et convaincante en quelques clics"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 * index }}
                  className="group p-8 rounded-xl bg-white/80 backdrop-blur-sm border border-gray-100 shadow-sm hover:shadow-md transition-all duration-300"
                >
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-50 to-violet-50 mb-6 flex items-center justify-center group-hover:from-blue-100 group-hover:to-violet-100 transition-colors duration-300">
                    <div className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Home;
